@import '../../node_modules/open-sans-fonts/open-sans.scss';

@media (min-width: 768px) {
	.section-sm {
		padding: 20px 0 70px;
        display: block;
        overflow: auto;
        &.subpages{
            &.content-fixed {
                @media (max-width: 991px) {
                    padding-top: 62px;
                }
                padding-top: 155px;
            }
        }
	}
}

$FontPathOpenSans: "../fonts/open-sans";